import { Component, Mixins } from 'vue-property-decorator'
import { CreateElement } from 'vue'

import { ReactiveProps } from '@/utils/reactive-props'
import { InjectReactive, Provide } from '@/vue-extensions'
import { Language } from '@/constants'
import I18n from '@/i18n.mixin'
import { INJECTIONS } from '@/injections'

@Component({
	lang: {
		[Language.ru]: {
			relogin: 'Авторизоваться в другой учетной записи?',
		},
		[Language.en]: {
			relogin: 'Sign in with a different account?',
		},
	},
})
export default class ErrorHandler extends Mixins(ReactiveProps, I18n) {
	notifications: any[] = []

	@Provide('handleError') handleError(e: Error) {
		if (e.message === 'ERR_CANCELED') return

		console.error(e)
		this.$store.commit('notifications/toast', {id: e.name, arg: e.message})
	}

	@InjectReactive(INJECTIONS.UI_LANGUAGE.CURRENT) currentUiLanguage: Language

	render(h: CreateElement) {
		return h('div', [
			this.$slots.default
		])
	}
}
