<template>
	<svg :x="x" :y="y"
	     :width="width" :height="height" viewBox="0 0 24 24">
		<line x1="2" y1="2" x2="22" y2="22"/>
		<line x1="22" y1="2" x2="2" y2="22"/>
	</svg>
</template>

<script>
export default {
	name: "Close",
	props: {
		x: {
			type: Number,
			default: 0
		},
		y: {
			type: Number,
			default: 0
		},
		height: {
			type: Number,
			default: 25
		},
		width: {
			type: Number,
			default: 25
		}
	}
}
</script>

<style lang="scss" scoped>
svg {
	line {
		stroke: #6b6b6c;
		stroke-width: 1px;
	}
}
</style>